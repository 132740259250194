<template>
  <div>
    <v-hover v-slot="{ hover }">
      <div>
        <v-card
          :to="'/menu/' + menu.menu_id"
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover }"
        >
          <div align="center" justify="center" class="pt-5">
            <v-img
              :aspect-ratio="16 / 9"
              src="/img/logo/list.png"
              max-height="150"
              max-width="150"
              contain
            >
            </v-img>
          </div>
          <!-- <v-icon :aspect-ratio="16 / 9" height="180px" class="mb-n3">mdi-menu</v-icon> -->
          <v-card-title class="text-caption justify-center text-uppercase">
            <p v-line-clamp:20="1" class="mb-0">
              <b>{{ menu.menu_slug }}</b>
            </p>
          </v-card-title>
          <!-- <v-card-subtitle
            class="
              text-caption
              text-sm-body-3
              text-md-body-2
              text-lg-body-2
              text-xl-body-2
              headerClass
            "
          >
            <small>{{ menu.category }}</small>
          </v-card-subtitle>
          <v-divider class="mt-n3"></v-divider>
          <v-card-actions
            class="
              text-caption
              text-sm-body-2
              text-md-body-1
              text-lg-body-1
              text-xl-body-1
            "
          >
            <v-icon small>mdi-format-list-bulleted</v-icon>
            <small>Stok {{ menu.stock }}</small>
            <v-spacer></v-spacer>
            <v-icon small>mdi-eye</v-icon>
            <small>{{ menu.views_count }}</small>
          </v-card-actions> -->
        </v-card>
      </div>
    </v-hover>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "menu-item",
  props: ["menu"],
  data: () => ({}),

  methods: {
    ...mapActions({
      addCart: "cart/add",
      setAlert: "alert/set",
    }),
    buy() {
      // alert('buy')
      // this.$store.dispatch('add', this.menu)
      this.addCart(this.menu);

      this.setAlert({
        status: true,
        color: "success",
        text: "Produk telah ditambahkan ke keranjang",
      });
    },
  },
};
</script>

<style scoped>
/* .v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
} */
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}

.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>